










































































import { Component, Vue } from 'vue-property-decorator'
import type { Currency, TokenType } from '@/types'
import { Conf, CurrencyType } from '@/models/Conf'
import {
	getAccounts,
	getAPIConfiguration,
	getSpotPairs,
	getTermDeposits,
	TermDepositItem,
	TradePair
} from '@/clients/cpinblocks'
import { Account } from '@/models/Account'
import {getGlobalConfInfo, guessAllTokensType} from '@/utils'

export class Token {
	currency: Currency | null = null
	types: TokenType[] = []
}

export class TokenList {
	tokens: Token[] = []

	public TokenList() {
		this.tokens = []
	}

	addCurrencyType(currency: Currency, type: TokenType) {
		for (let t of this.tokens) {
			if (t.currency && t.currency === currency) {
				if (!t.types) {
					t.types = []
				}
				t.types.push(type)
				t.types = [ ...new Set(t.types) ].sort()
				return
			}
		}
		this.tokens.push({ currency, types: [ type ]})
		this.tokens.sort((a: Token, b: Token) => a.currency && b.currency ? a.currency.localeCompare(b.currency) : 1)
	}
}

@Component({})
export default class ConfStatus extends Vue {

	private accounts: Account[] = []
	private conf: Conf | null = null
	private headers = [
		{
			align: 'left',
			sortable: false,
			value: 'logo',
			text: 'Logo',
		},
		{
			align: 'left',
			sortable: false,
			value: 'currency',
			text: 'Currency',
		},
		{
			align: 'left',
			sortable: false,
			value: 'type',
			text: 'Type',
		},
		{
			align: 'left',
			sortable: false,
			value: 'address',
			text: 'Address',
		},
		{
			align: 'left',
			sortable: false,
			value: 'explorable',
			text: 'Explorable',
		},
		{
			align: 'left',
			sortable: false,
			value: 'transferable',
			text: 'Transferable',
		},
		{
			align: 'left',
			sortable: false,
			value: 'deposit',
			text: 'Deposit',
		},
		{
			align: 'left',
			sortable: false,
			value: 'withdraw',
			text: 'Withdraw',
		},
		{
			align: 'left',
			sortable: false,
			value: 'convertible',
			text: 'Convertible',
		},
		{
			align: 'left',
			sortable: false,
			value: 'otc',
			text: 'OTC',
		},
		{
			align: 'left',
			sortable: false,
			value: 'spot',
			text: 'Spot',
		},
		{
			align: 'left',
			sortable: false,
			value: 'staking',
			text: 'Staking',
		},
		{
			align: 'left',
			sortable: false,
			value: 'termDeposit',
			text: 'Term deposit',
		},
	]
	private items: object[] = []
	private loading = true
	private spotPairs: TradePair[] | null = null
	private termDeposits: TermDepositItem[] = []
	private tokenList = new TokenList()

	private async mounted (): Promise<void> {
		this.accounts = await getAccounts(this.$store.state.jwt, false)
		this.conf = await getAPIConfiguration()
		this.spotPairs = await getSpotPairs()
		this.termDeposits = await getTermDeposits(this.$store.state.jwt)
		if (this.conf) {
			this.tokenList = guessAllTokensType(this.conf, this.accounts, this.spotPairs, this.termDeposits)
			for (const c of this.tokenList.tokens) {
				const infos = getGlobalConfInfo(this.conf, this.spotPairs, this.termDeposits, c)
				if (infos) {
					for (const i of infos) {
						this.items.push(i)
					}
				}
			}
			this.items = this.items.sort()
		}
		this.loading = false
	}

	private getImage(currency: Currency | null) {
		if (currency === null) {
			return null
		}
		const c = currency.toLowerCase()
		try {
			let filename = require(`@/assets/tokens/${c}.png`)
			return filename
		} catch (e) {
			// do nothing
		}
		return null
	}

}
