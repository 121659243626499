var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"item-key":"id","items":_vm.items,"hide-default-footer":"","items-per-page":100},scopedSlots:_vm._u([{key:"item.address",fn:function(ref){
var item = ref.item;
return [(item.address !== null)?_c('a',{attrs:{"href":item.address}},[_vm._v(_vm._s(item.blockchain))]):_vm._e()]}},{key:"item.convertible",fn:function(ref){
var item = ref.item;
return _vm._l((item.convertible),function(i){return _c('div',{key:i},[_vm._v(" "+_vm._s(i.currency + ' ' + i.type)+" ")])})}},{key:"item.deposit",fn:function(ref){
var item = ref.item;
return [(item.deposit === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):(item.deposit === false)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")]):_c('div')]}},{key:"item.explorable",fn:function(ref){
var item = ref.item;
return [(item.explorable === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):(item.explorable === false)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")]):_c('div')]}},{key:"item.logo",fn:function(ref){
var item = ref.item;
return [(_vm.getImage(item.currency))?_c('v-img',{staticClass:"ma-2",staticStyle:{"cursor":"pointer"},attrs:{"contain":"","src":_vm.getImage(item.currency),"max-height":"32px","max-width":"32px"}}):_vm._e()]}},{key:"item.otc",fn:function(ref){
var item = ref.item;
return _vm._l((item.otc),function(i){return _c('div',{key:i},[_vm._v(" "+_vm._s(i.currency + ' ' + i.type)+" ")])})}},{key:"item.spot",fn:function(ref){
var item = ref.item;
return _vm._l((item.spot),function(i){return _c('div',{key:i},[_vm._v(" "+_vm._s(i.currency + ' ' + i.type)+" ")])})}},{key:"item.staking",fn:function(ref){
var item = ref.item;
return [(item.staking === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):(item.staking === false)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")]):_c('div')]}},{key:"item.termDeposit",fn:function(ref){
var item = ref.item;
return [(item.termDeposit === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):(item.termDeposit === false)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")]):_c('div')]}},{key:"item.transferable",fn:function(ref){
var item = ref.item;
return [(item.transferable === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):(item.transferable === false)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")]):_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"orange"}},on),[_vm._v(" mdi-check ")])]}}],null,true)},[_c('span',[_vm._v("Available only for "+_vm._s(item.transferable.join(', ')))])])]}},{key:"item.withdraw",fn:function(ref){
var item = ref.item;
return [(item.withdraw === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):(item.withdraw === false)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")]):_c('div')]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }